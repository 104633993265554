import React from 'react';
import { connectHits, Pagination } from 'react-instantsearch-dom';
import { HitsProvided } from 'react-instantsearch-core';
import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ResultEntry from '../result-entry';
import ResultsTable from '../results-table';
import ScreenerStats from './screener-stats';
import { ScreenerFieldData } from '../interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resultsContainer: {
      marginBottom: theme.spacing(4),
    },
    pagination: {
      paddingBottom: theme.spacing(4),
    },
  })
);

interface ScreenerResultsProps {
  activeScreenerFields: ScreenerFieldData[];
  isQueryEmpty: boolean;
}
// Component for diplaying search results
// "hits" prop are retrieved from "connectHits" wrapping the "ScreenerResults" component
const ScreenerResults: React.FunctionComponent<
  HitsProvided<any> & ScreenerResultsProps
> = ({ hits, activeScreenerFields, isQueryEmpty }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isQueryEmpty ? null : (
    <>
      <ScreenerStats />

      <div className={classes.resultsContainer}>
        {isMobile ? (
          hits.map((hit: any) => (
            // Result view for mobile
            <ResultEntry key={hit['objectID']} hit={hit} />
          ))
        ) : (
          // Result view for larger screens
          <ResultsTable
            hits={hits}
            activeScreenerFields={activeScreenerFields}
          ></ResultsTable>
        )}
      </div>

      <Pagination className={classes.pagination} showFirst showLast />
    </>
  );
};

export default connectHits(ScreenerResults);
