import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  CartesianGrid,
  XAxis,
  Tooltip,
  YAxis,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
} from 'recharts';
import { MarketFactorPerformanceGraphProps } from '../interfaces';
import { getYearOfSimpleDate } from '../../utils/getYearOfSimpleDate';
import calculateInterval from './calculateInterval';
import CustomTooltip from './CustomTooltip';
import calculateYears from '../../utils/calculateYears';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { height: '100%', minHeight: '300px' },
    historyGraph: {
      marginTop: theme.spacing(4),
      height: '100%',
      minHeight: '300px',
    },
    toolTip: {
      backgroundColor: 'rgba(255,255,255, 0.6)',
      paddingTop: '1px',
      paddingBottom: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
    },
    labelText: {
      fontWeight: 'bold',
      marginBottom: '0px',
      paddingBottom: '0px',
    },
    labelHeader: {
      marginBottom: '0px',
      paddingBottom: '0px',
    },
  })
);

const CustomizedAxisTick: React.FunctionComponent<any> = ({
  x,
  y,
  stroke,
  payload,
  tickItemFormatter,
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {tickItemFormatter(payload.value)}
      </text>
    </g>
  );
};

const MarketFactorPerformanceGraph: React.FunctionComponent<MarketFactorPerformanceGraphProps> = ({
  data,
  isMobile,
}) => {
  const classes = useStyles();

  const tickItemFormatter = (tickItem: any) => {
    const year = getYearOfSimpleDate(tickItem);
    if (calculateYears(data) > 1) {
      return year;
    }
    return tickItem;
  };

  return (
    <div className={classes.root}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{ top: 10, right: 50, left: 0, bottom: 60 }}
          style={{ paddingTop: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="simple_date"
            interval={calculateInterval(data)}
            tick={<CustomizedAxisTick tickItemFormatter={tickItemFormatter} />}
          />
          <YAxis />
          {isMobile ? null : <Tooltip content={<CustomTooltip />} />}
          <Legend verticalAlign="top" />
          <Line
            type="monotone"
            dataKey="Low_market_beta"
            name="Low Market Beta"
            stroke="#6bb7b6"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="Market_excess_return"
            name="Market Excess Return"
            stroke="#17889b"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="Momentum"
            stroke="rgba(0, 0, 0, 0.54)"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="Small_cap"
            name="Small Cap"
            stroke="#141c24"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="Quality"
            stroke="#b84dff"
            dot={false}
          />

          <Line type="monotone" dataKey="Value" stroke="#ff370f" dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MarketFactorPerformanceGraph;
