import React from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TopNavBar from './top-navbar';
import LeftMenu from './left-menu';
import { useLocation } from 'react-router-dom';
import { drawerWidthWide } from '../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: theme.palette.background.default,
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,

    contentPaddingContainer: {
      flexGrow: 1,
      padding: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },
      maxWidth: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(100% - ${drawerWidthWide}px)`,
      },
      height: '100vh',
      width: 'calc(100% - 200px)',
    },

    contentCenteringContainer: {
      margin: '0 auto',

      minHeight: '300px',
      height: 'auto',
    },
  })
);

// Maincomponent with the "TopNavBar" component and the "LeftMenu" component
// also displaying the content of the screener or fund report as "children" in the center
const MainLayout: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const location = useLocation();

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className={classes.root}>
      <TopNavBar onMobileMenuButtonClick={handleMobileMenuToggle} />

      <LeftMenu
        isMobileMenuOpen={isMobileMenuOpen}
        onMobileMenuToggle={handleMobileMenuToggle}
        backButtonActive={location.pathname !== '/'}
      />

      <main className={classes.contentPaddingContainer}>
        <div className={classes.toolbar} />

        <div className={classes.contentCenteringContainer}>{children}</div>
      </main>
    </div>
  );
};

export default MainLayout;
