import React from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Collapse,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
  IconButton,
  Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useAuth0 } from '@auth0/auth0-react';

import { drawerWidthWide } from '../config';
import NorquantLogo from '../assets/norquant_logo_black_text.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        flexShrink: 0,
      },
      // [theme.breakpoints.only('sm')]: {
      //   width: drawerWidthNarrow,
      // },
      [theme.breakpoints.up('md')]: {
        width: drawerWidthWide,
      },
    },

    nestedMenuItem: {
      paddingLeft: theme.spacing(4),
    },

    mainMenuItemText: {
      fontWeight: 'bold',
    },

    drawerPaper: {
      width: drawerWidthWide,
      // [theme.breakpoints.only('sm')]: {
      //   width: 200,
      // },
      backgroundColor: '#f5f9f8',
    },
    accountIconButton: {
      margin: theme.spacing(0, 1),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 2),
      },
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    user: {
      display: 'flex',
      justifyContent: 'space-evenly',
      padding: theme.spacing(2),
    },

    topLogoContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      backgroundColor: theme.palette.background.default,

      '& img': {
        height: 40,
      },
    },
  })
);

interface LeftMenuProps {
  isMobileMenuOpen: boolean;
  onMobileMenuToggle: () => void;
  backButtonActive: boolean;
}

const loginButton = (
  <Button
    color="inherit"
    variant="outlined"
    size="small"
    disableElevation
    // onClick={() => loginWithRedirect()}
  >
    Login
  </Button>
);

const logoutButton = (
  <Button
    color="inherit"
    variant="outlined"
    size="small"
    disableElevation
    // onClick={() => logout({ returnTo: window.location.origin })}
  >
    Logout
  </Button>
);

const LeftMenu: React.FunctionComponent<LeftMenuProps> = ({
  isMobileMenuOpen,
  onMobileMenuToggle,
  backButtonActive,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isAuthenticated } = useAuth0();

  const [isMyListsOpen, setIsMyListsOpen] = React.useState(true);
  const [isMyScreenersOpen, setIsMyScreenersOpen] = React.useState(true);

  const handleMyListsClick = () => {
    setIsMyListsOpen(!isMyListsOpen);
  };

  const handleMyScreenersClick = () => {
    setIsMyScreenersOpen(!isMyScreenersOpen);
  };

  const menuContent = (
    <>
      <ListItem
        key="RouterLink"
        button
        component={RouterLink}
        to="/"
        className={`${classes.toolbar} ${classes.topLogoContainer}`}
      >
        <img src={NorquantLogo} alt="Norquant logo" />
      </ListItem>

      <Divider />
      {isMobile ? (
        <div className={classes.user}>
          <IconButton
            color="inherit"
            aria-label="account"
            className={classes.accountIconButton}
            // component={RouterLink}
            // to="/account"
          >
            <AccountCircleIcon />
          </IconButton>
          {isAuthenticated ? logoutButton : loginButton}
        </div>
      ) : null}
      <Divider />

      <List>
        {backButtonActive ? (
          <ListItem key="active" button component={RouterLink} to="/">
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Back to Screener"></ListItemText>
          </ListItem>
        ) : (
          <ListItem key="inactive" button component={RouterLink} to="/">
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Fund Screener"></ListItemText>
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem key="myLists" button onClick={handleMyListsClick}>
          {/* <ListItemIcon>
            <ListIcon />
          </ListItemIcon> */}
          <ListItemText
            primary="Stored Funds"
            className={classes.mainMenuItemText}
          />
          {isMyListsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={isMyListsOpen} timeout="auto">
          <List component="div" disablePadding>
            {['Fund 1', 'Fund 2', 'Fund 3'].map((text, index) => (
              <ListItem button key={text} className={classes.nestedMenuItem}>
                {/* <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon> */}
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>

      <Divider />

      <List>
        <ListItem key="screenerClick" button onClick={handleMyScreenersClick}>
          <ListItemText
            primary="Stored Screens"
            className={classes.mainMenuItemText}
          />
          {isMyScreenersOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={isMyScreenersOpen} timeout="auto">
          <List component="div" disablePadding>
            {['Search 1', 'Search 2', 'Search 3'].map((text, index) => (
              <ListItem button key={text} className={classes.nestedMenuItem}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>

      {/* <Divider /> */}
    </>
  );

  return (
    <nav className={classes.drawer} aria-label="Left menu">
      {/* Mobile */}
      <Hidden mdUp implementation="js">
        <SwipeableDrawer
          variant="temporary"
          anchor={'left'}
          open={isMobileMenuOpen}
          onOpen={onMobileMenuToggle}
          onClose={onMobileMenuToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {menuContent}
        </SwipeableDrawer>
      </Hidden>

      {/* Desktop */}
      <Hidden smDown implementation="js">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {menuContent}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default LeftMenu;
