import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import {
  ScreenerFieldData,
  ScreenerFieldMetaData,
  categoryToDisplayNameMap,
  Category,
} from './interfaces';

// Much of file copied from https://material-ui.com/components/tabs/#vertical-tabs

interface TabPanelProps {
  index: number;
  value: number;
}

const useStylesTabPanel = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  tabPanelBox: {
    height: '100%',

    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 3),
    },
  },
}));

const TabPanel: React.FunctionComponent<TabPanelProps> = ({
  value,
  index,
  children,
  ...other
}) => {
  const classes = useStylesTabPanel();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className={classes.root}
    >
      {value === index && <Box className={classes.tabPanelBox}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStylesFieldsToggler = makeStyles((theme: Theme) => ({
  heading: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tabsBox: {
    display: 'flex',
    height: 244,
    [theme.breakpoints.down(1088)]: {
      height: 'inherit',
    },
  },
  tabs: {
    padding: theme.spacing(2, 0),
    minWidth: 160,
    [theme.breakpoints.down('xs')]: {
      minWidth: 120,
    },
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    minHeight: 42,
  },
  fieldLabel: {
    maxWidth: 300,
    '& > :first-child': {
      // padding: 6,
    },
  },
  fieldTogglesFormGroup: {
    height: '100%',
  },
}));

interface FieldsPickerProps {
  fieldsMetaData: ScreenerFieldMetaData[];
  activeFields: ScreenerFieldData[];
  onAddActiveField: (fieldName: string) => void;
  onRemoveActiveField: (fieldName: string) => void;
}

const FieldsToggler: React.FunctionComponent<FieldsPickerProps> = ({
  fieldsMetaData,
  activeFields,
  onAddActiveField,
  onRemoveActiveField,
}) => {
  const classes = useStylesFieldsToggler();
  const [activeCategoryTab, setActiveCategoryTab] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveCategoryTab(newValue);
  };

  const isActiveFieldName = (fieldName: string) =>
    activeFields.findIndex(
      (activeField) => activeField.metadata.fieldName === fieldName
    ) >= 0;

  return (
    <Box className={classes.tabsBox}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={activeCategoryTab}
        onChange={handleChange}
        aria-label="Category tabs"
        className={classes.tabs}
      >
        {Object.keys(Category).map((category, index) => (
          <Tab
            key={category}
            className={classes.tab}
            label={categoryToDisplayNameMap.get(category as Category)}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>

      {Object.keys(Category).map((category, index) => (
        <TabPanel value={activeCategoryTab} index={index} key={category}>
          <FormGroup className={classes.fieldTogglesFormGroup}>
            {fieldsMetaData
              .filter((fieldMetaData) => fieldMetaData.category === category)
              .map((fieldMetaData, index) => (
                <FormControlLabel
                  className={classes.fieldLabel}
                  key={index}
                  control={
                    <Checkbox
                      checked={isActiveFieldName(fieldMetaData.fieldName)}
                      onChange={(event, checked) =>
                        checked
                          ? onAddActiveField(fieldMetaData.fieldName)
                          : onRemoveActiveField(fieldMetaData.fieldName)
                      }
                      name={fieldMetaData.fieldName}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {fieldMetaData.description}
                    </Typography>
                  }
                />
              ))}
          </FormGroup>
        </TabPanel>
      ))}
    </Box>
  );
};

export default FieldsToggler;
