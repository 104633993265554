import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { ScreenerFieldData } from './interfaces';
import { Highlight } from 'react-instantsearch-dom';
import { CustomHighlight } from './CustomHighlight';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useScreener, ScreenerContextProps } from '../hooks/screener';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    nameColumn: {
      minWidth: 160,
    },
    tableColumn: {
      minWidth: 140,
    },
  })
);

interface ResultsTableProps {
  hits: any[];
  activeScreenerFields?: ScreenerFieldData[];
}

// Component for showing results on desktop
const ResultsTable: React.FunctionComponent<ResultsTableProps> = ({
  hits,
  activeScreenerFields = [],
}) => {
  const classes = useStyles();
  const screener = useScreener() as ScreenerContextProps;

  const [
    contextMenuAnchorEl,
    setContextMenuAnchorEl,
  ] = React.useState<null | HTMLElement>(null);

  const handleContextMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setContextMenuAnchorEl(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchorEl(null);
  };

  const fundRowContextMenu = (
    <Menu
      id="fund-context-menu"
      anchorEl={contextMenuAnchorEl}
      keepMounted
      open={Boolean(contextMenuAnchorEl)}
      onClose={handleContextMenuClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <MenuItem key="menuItem" onClick={handleContextMenuClose}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="Add to list"></ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {fundRowContextMenu}

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {/* Default fields always showing */}
              <TableCell className={classes.nameColumn}>Name</TableCell>
              <TableCell className={classes.tableColumn}>ISIN</TableCell>
              <TableCell className={classes.tableColumn}>Currency</TableCell>
              {/* Fields based on selected filters */}
              {activeScreenerFields.map((screenerField) => {
                if (
                  screenerField.metadata.algoliaAttribute !== 'infront_currency'
                ) {
                  return screenerField.metadata.fieldType !== 'MULTI_SELECT' ? (
                    <TableCell
                      key={screenerField.metadata.fieldName}
                      className={classes.tableColumn}
                      sortDirection={screener.isDesc ? 'desc' : 'asc'}
                    >
                      <TableSortLabel
                        active={
                          screener.orderBy === screenerField.metadata.fieldName
                        }
                        direction={screener.isDesc ? 'desc' : 'asc'}
                        onClick={() =>
                          screener.handleSetIndexName(
                            screenerField.metadata.fieldName
                          )
                        }
                      >
                        {screenerField.metadata.description}
                      </TableSortLabel>
                    </TableCell>
                  ) : (
                    <TableCell
                      key={screenerField.metadata.fieldName}
                      className={classes.tableColumn}
                    >
                      {screenerField.metadata.description}
                    </TableCell>
                  );
                }
                return null;
              })}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          {/* Results mapped */}
          <TableBody>
            {hits.map((hit: any, index: number) => (
              <TableRow key={hit['objectID']} hover>
                <TableCell>
                  <CustomHighlight
                    attribute="factset_fund_name"
                    hit={hit}
                    tagName="mark"
                  />
                </TableCell>
                <TableCell>
                  <Highlight attribute="isin" hit={hit} tagName="mark" />
                </TableCell>
                <TableCell>
                  <Highlight
                    attribute="infront_currency"
                    hit={hit}
                    tagName="currency"
                  />
                </TableCell>
                {activeScreenerFields.map((screenerField) => {
                  if (
                    screenerField.metadata.algoliaAttribute !==
                    'infront_currency'
                  ) {
                    return (
                      <TableCell key={screenerField.metadata.fieldName}>
                        {screenerField.metadata.algoliaAttribute &&
                          hit[screenerField.metadata.algoliaAttribute]}
                      </TableCell>
                    );
                  }
                  return null;
                })}
                <TableCell>
                  <div className={classes.buttonsContainer}>
                    <IconButton
                      aria-label="More actions"
                      onClick={handleContextMenuClick}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<ShowChartIcon />}
                      disableElevation
                      component={Link}
                      to={`/${hit['isin']}/${hit['infront_currency']}`}
                    >
                      View
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResultsTable;
