import React, { useState } from 'react';
import clsx from 'clsx';
import { Box, Button, Collapse, makeStyles, Theme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  toggleButton: {
    width: '100%',
  },
  expandIcon: {
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandIconOpen: {
    transform: 'rotate(0deg)',
  },
  contentWithBorderTop: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

interface ExpandToggleProps {
  label: string;
  initiallyExpanded?: boolean;
  borderTopOverContent?: boolean;
  addedClass?: any;
}

const ExpandToggle: React.FunctionComponent<ExpandToggleProps> = ({
  label,
  initiallyExpanded = false,
  borderTopOverContent = true,
  addedClass,
  children,
}) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  const toggleIsExpanded = () => setIsExpanded((isExpanded) => !isExpanded);

  return (
    <Box className={clsx(classes.root, addedClass)}>
      <Button
        className={classes.toggleButton}
        aria-expanded={isExpanded}
        onClick={toggleIsExpanded}
      >
        <ExpandMoreIcon
          className={clsx(classes.expandIcon, {
            [classes.expandIconOpen]: isExpanded,
          })}
        />
        {label}
      </Button>

      <Collapse
        in={isExpanded}
        className={clsx({
          [classes.contentWithBorderTop]: borderTopOverContent,
        })}
      >
        {children}
      </Collapse>
    </Box>
  );
};

export default ExpandToggle;
