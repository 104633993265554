import { drawerWidthWide } from '../config';
import React from 'react';
import {
  AppBar,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidthWide}px)`,
        marginLeft: drawerWidthWide,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    navBarTitle: {
      flexGrow: 1,
      fontWeight: 300,
      fontStyle: 'italics',
      fontFamily: 'Lato, sans-serif',
      fontSize: 22,
      [theme.breakpoints.up('sm')]: {
        fontSize: 32,
      },
    },
    accountIconButton: {
      margin: theme.spacing(0, 1),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 2),
      },
    },
  })
);

interface NavbarProps {
  onMobileMenuButtonClick: () => void;
}

const TopNavBar: React.FunctionComponent<NavbarProps> = ({
  onMobileMenuButtonClick,
}) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const loginButton = (
    <Button color="inherit" variant="outlined" size="small" disableElevation>
      Login
    </Button>
  );

  const logoutButton = (
    <Button color="inherit" variant="outlined" size="small" disableElevation>
      Logout
    </Button>
  );

  return (
    <AppBar
      position="fixed"
      color="primary"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onMobileMenuButtonClick}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <Typography
          variant="h6"
          align="center"
          noWrap
          className={classes.navBarTitle}
        >
          Quantitative Fund Analysis Tool
        </Typography>
        {!isMobile ? (
          <IconButton
            color="inherit"
            aria-label="account"
            className={classes.accountIconButton}
          >
            <AccountCircleIcon />
          </IconButton>
        ) : null}

        {!isMobile ? (isAuthenticated ? logoutButton : loginButton) : null}
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;
