import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface CheckboxProps {
  setChecked: (cheked: any) => void;
  checked: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(2),
    },
  })
);

const CheckboxLabels: React.FunctionComponent<CheckboxProps> = ({
  setChecked,
  checked,
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  return (
    <FormGroup row className={classes.root}>
      <FormControlLabel
        key="absolute_fund_return"
        control={
          <Checkbox
            checked={checked.absolute_fund_return}
            onChange={handleChange}
            name="absolute_fund_return"
            color="primary"
          />
        }
        label="Absolute Return"
      />
      <FormControlLabel
        key="market_return_contribution"
        control={
          <Checkbox
            checked={checked.market_return_contribution}
            onChange={handleChange}
            name="market_return_contribution"
            color="primary"
          />
        }
        label="Market Return"
      />
      <FormControlLabel
        key="small_cap_return_contribution"
        control={
          <Checkbox
            checked={checked.small_cap_return_contribution}
            onChange={handleChange}
            name="small_cap_return_contribution"
            color="primary"
          />
        }
        label="Small Cap"
      />
      <FormControlLabel
        key="value_return_contribution"
        control={
          <Checkbox
            checked={checked.value_return_contribution}
            onChange={handleChange}
            name="value_return_contribution"
            color="primary"
          />
        }
        label="Value"
      />
      <FormControlLabel
        key="quality_return_contribution"
        control={
          <Checkbox
            checked={checked.quality_return_contribution}
            onChange={handleChange}
            name="quality_return_contribution"
            color="primary"
          />
        }
        label="Quality"
      />
      <FormControlLabel
        key="momentum_return_contribution"
        control={
          <Checkbox
            checked={checked.momentum_return_contribution}
            onChange={handleChange}
            name="momentum_return_contribution"
            color="primary"
          />
        }
        label="Momentum"
      />
      <FormControlLabel
        key="low_beta_return_contribution"
        control={
          <Checkbox
            checked={checked.low_beta_return_contribution}
            onChange={handleChange}
            name="low_beta_return_contribution"
            color="primary"
          />
        }
        label="Low Beta"
      />
      <FormControlLabel
        key="alpha_return"
        control={
          <Checkbox
            checked={checked.alpha_return}
            onChange={handleChange}
            name="alpha_return"
            color="primary"
          />
        }
        label="Alpha"
      />
      <FormControlLabel
        key="aggregate_factor_return_contribution"
        control={
          <Checkbox
            checked={checked.aggregate_factor_return_contribution}
            onChange={handleChange}
            name="aggregate_factor_return_contribution"
            color="primary"
          />
        }
        label="Aggregate Factor"
      />
    </FormGroup>
  );
};

export default CheckboxLabels;
