import isProd from './helpers/dev-detect';

const useDevBackendInDevelopment = true;

const prodApiUrl = 'https://api.norquant.no/api';
const localDevApiUrl = 'http://localhost:3333/api';

export const API_URL =
  isProd() || !useDevBackendInDevelopment ? prodApiUrl : localDevApiUrl;

export const auth0Domain = 'norquant-dev.eu.auth0.com';
export const auth0ApiIdentifier = 'https://api.norquant.no';
export const auth0ClientId = 'X8Z6PqTlZyFcPQPFTirMZnkdcmoYle6x';

export const algoliaApplicationID = '3GX5APYLE6';
export const algoliaSeachKey = '25f4f94ab6175e4ef240f4b744106ed6';

export const drawerWidthWide = 240;
export const drawerWidthNarrow = 200;

export const screenerFieldsMetaDataFilePath =
  'https://norquant-fund-tool-public.s3.eu-north-1.amazonaws.com/screener-fields-meta.json';
