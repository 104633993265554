export enum Category {
  PROFILE = 'PROFILE',
  LOCATION = 'LOCATION',
  PERFORMANCE = 'PERFORMANCE',
  RISK = 'RISK',
  FACTOR = 'FACTOR',
}

export const categoryToDisplayNameMap: Map<Category, string> = new Map([
  [Category.PROFILE, 'Profile'],
  [Category.LOCATION, 'Location'],
  [Category.PERFORMANCE, 'Performance'],
  [Category.RISK, 'Risk'],
  [Category.FACTOR, 'Factor Exposure'],
]);

export enum ScreenerFieldInputType {
  TEXT = 'TEXT',
  MULTI_SELECT = 'MULTI_SELECT',
  INTEGER_RANGE = 'INTEGER_RANGE',
  DECIMAL_RANGE = 'DECIMAL_RANGE',
  PERCENTAGE_RANGE = 'PERCENTAGE_RANGE',
  CATEGORY_SELECTOR = 'CATEGORY_SELECTOR',
}

export interface ScreenerFieldData {
  metadata: ScreenerFieldMetaData;
  parameters: FieldParameters;
  values: FieldValue;
}

export interface ScreenerFieldMetaData {
  fieldName: string;
  category: string;
  fieldType: string;
  description: string;
  algoliaAttribute?: string;
  minValue?: number;
  maxValue?: number;
  initialOptions?: string[];
}

export type FromToValues = { fromValue: number; toValue: number };

export type FieldParameters = FromToParameters | SelectParameters | {};

export interface FromToParameters {
  minValue: number;
  maxValue: number;
}

export interface SelectParameters {
  multiSelectOptions: string[];
}

export interface FieldValue {
  fromValue?: number;
  toValue?: number;
  selectedOptions?: string[];
}
