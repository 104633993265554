import React, { useEffect, useState } from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import UndoIcon from '@material-ui/icons/Undo';
import algoliasearch from 'algoliasearch/lite';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { SearchState } from 'react-instantsearch-core';

import { algoliaApplicationID, algoliaSeachKey } from '../config';
import {
  FieldParameters,
  ScreenerFieldData,
  ScreenerFieldInputType,
  ScreenerFieldMetaData,
} from './interfaces';
import FieldsToggler from './fields-toggler';
import Spinner from '../ui/spinner';
import ExpandPanel from '../ui/expand-panel';
import { isNumericRangeInputType } from './screener-field-inputs/helpers';
import ScreenerActiveFieldInputs from './screener-active-field-inputs';
import ScreenerSearchBox from './algolia-ui-elements/screener-search-box';
import ScreenerResults from './algolia-ui-elements/screener-results';
import meta from '../assets/meta.json';
import { useScreener, ScreenerContextProps } from '../hooks/screener';

const HITS_PER_PAGE = 10;

// Establish connection with Algolia
const searchClient = algoliasearch(algoliaApplicationID, algoliaSeachKey);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenerForm: {
      margin: theme.spacing(2, 0),
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
      marginBottom: theme.spacing(3),
    },
    fullWidthTextField: {
      width: '100%',
    },
    currencyTextField: {
      width: 200,
    },
    bottomButtons: {
      display: 'flex',
      // justifyContent: 'flex-end',
      margin: theme.spacing(2, 0, 3, 0),
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
    bottomButtonsFillRemaining: {
      flexGrow: 1,
    },
  })
);

export interface SearchTerms {
  nameOrISIN: string;
  currency: string;
}

const AlgoliaScreener: React.FunctionComponent = () => {
  const classes = useStyles();

  // Metadata for algolia screener, this metadata should match the available factsets set up in algolia
  // the metadata also describes which type of data it is and whcih compoents to use for the screener
  const [fieldsMetaData, setFieldsMetaData] = useState<ScreenerFieldMetaData[]>(
    []
  );

  const screenerHook = useScreener() as ScreenerContextProps;

  useEffect(() => {
    // Fetch metadata for algolia screener from local file
    (async () => {
      setFieldsMetaData(meta.fields);
    })();
  }, []);

  const [activeFieldsAllData, setActiveFieldsAllData] = useState<
    ScreenerFieldData[]
  >([]);

  const [displayFieldsState] = useState(false);

  const handleAddActiveField = (fieldName: string) => {
    const fieldToAddMetaData = fieldsMetaData.find(
      (field) => field.fieldName === fieldName
    );

    if (fieldToAddMetaData) {
      const newFieldParameters: FieldParameters = isNumericRangeInputType(
        fieldToAddMetaData.fieldType
      )
        ? {
            minValue:
              fieldToAddMetaData.minValue !== undefined
                ? fieldToAddMetaData.minValue
                : 0,
            maxValue:
              fieldToAddMetaData.maxValue !== undefined
                ? fieldToAddMetaData.maxValue
                : 100,
          }
        : fieldToAddMetaData.fieldType === ScreenerFieldInputType.MULTI_SELECT
        ? {
            multiSelectOptions:
              fieldToAddMetaData.initialOptions !== undefined
                ? fieldToAddMetaData.initialOptions
                : [],
          }
        : {};

      setActiveFieldsAllData((activeFieldsAllData) =>
        activeFieldsAllData.concat([
          {
            metadata: fieldToAddMetaData,
            parameters: newFieldParameters,
            values: {},
          },
        ])
      );
    }
  };

  const handleRemoveActiveField = (fieldName: string) => {
    const indexOfFieldToRemove = activeFieldsAllData.findIndex(
      (activeField) => activeField.metadata.fieldName === fieldName
    );

    if (indexOfFieldToRemove >= 0) {
      setActiveFieldsAllData((activeFieldsAllData) => {
        const activeFieldsAllDataCopy = [...activeFieldsAllData];
        activeFieldsAllDataCopy.splice(indexOfFieldToRemove, 1);
        return activeFieldsAllDataCopy;
      });
    }
  };

  const handleSetFieldValue = (fieldIndex: number, newValue: any) => {
    setActiveFieldsAllData((activeFieldsAllData) => {
      const activeFieldsAllDataCopy = [...activeFieldsAllData];
      activeFieldsAllDataCopy[fieldIndex].values = newValue;
      return activeFieldsAllDataCopy;
    });
  };

  const resetScreen = () => {
    setActiveFieldsAllData([]);
  };

  // const [isSearching, setIsSearching] = useState(false);
  // const [results, setResults] = useState([]);

  // useEffect(
  //   () => {
  //     if (debouncedSearchTerms) {
  //       setIsSearching(true);
  //       await useApi()
  //       setIsSearching(false);
  //       setResults(results);
  //     } else {
  //       setResults([]);
  //     }
  //   },
  //   [debouncedSearchTerm] // Only call effect if debounced search term changes
  // );

  const [isQueryEmpty, setIsQueryEmtpy] = useState(true);

  const handleSearchStateChange = (searchState: SearchState) => {
    setIsQueryEmtpy(searchState.query === '');
  };

  return (
    // This component is used to connect Algolia to the react web app
    <InstantSearch
      searchClient={searchClient}
      indexName={screenerHook.indexName}
      onSearchStateChange={handleSearchStateChange}
    >
      {activeFieldsAllData.length > 0 ? (
        <Configure hitsPerPage={HITS_PER_PAGE} />
      ) : (
        <Configure hitsPerPage={HITS_PER_PAGE} filters="" />
      )}

      <form className={classes.screenerForm} noValidate autoComplete="off">
        <ScreenerSearchBox />

        {/* ExpandPanel component with all screening possibuilities */}
        <ExpandPanel label="Add or remove criteria">
          {fieldsMetaData ? (
            <FieldsToggler
              fieldsMetaData={fieldsMetaData}
              activeFields={activeFieldsAllData}
              onAddActiveField={handleAddActiveField}
              onRemoveActiveField={handleRemoveActiveField}
            />
          ) : (
            <Spinner />
          )}
        </ExpandPanel>

        <ScreenerActiveFieldInputs
          activeFields={activeFieldsAllData}
          onSetFieldValue={handleSetFieldValue}
        />
      </form>
      {/* TODO: The functionallity for these buttons are not implemented yet 
      and are only displayed for demo purpouses */}
      <div className={classes.bottomButtons}>
        <div className={classes.bottomButtonsFillRemaining} />

        <Button
          color="primary"
          variant="outlined"
          startIcon={<UndoIcon />}
          disableElevation
          onClick={() => resetScreen()}
        >
          Reset
        </Button>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          disableElevation
        >
          Save Search
        </Button>
      </div>

      {displayFieldsState && (
        <div>
          <pre>{JSON.stringify(activeFieldsAllData, null, 2)}</pre>
        </div>
      )}
      {/* Component for displaying the search results */}
      <ScreenerResults
        activeScreenerFields={activeFieldsAllData}
        isQueryEmpty={isQueryEmpty}
      />
    </InstantSearch>
  );
};

export default AlgoliaScreener;
