import React from 'react';
import MainLayout from './layout/main-layout';
import { Route, Switch } from 'react-router-dom';
import AlgoliaScreener from './screener/algolia-screener';
import FundReport from './fund-report/fund-report';
import { ScreenerProvider } from './hooks/screener';

// Main component which everiything springs out of.
// It's using react router to route the app to different endpoints

const App = () => {
  return (
    <MainLayout>
      <Switch>
        <Route key="AlgoliaScreener" path="/" exact>
          {/* Using Context and Hooks to provide data for the "AlgoliaScreener" component */}
          <ScreenerProvider>
            {/* Landing page and the component used for filtering data */}
            <AlgoliaScreener />
          </ScreenerProvider>
        </Route>
        <Route key="FundReport" path="/:ISIN/:currency">
          {/* Component directed to when accessing a sepsific fund. 
          This component displays the information about the spesific fund  */}
          <FundReport />
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default App;
