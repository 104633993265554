import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface YearSelectProps {
  setSelectedYear: (selecte: number) => void;
  selectedYear: number;
  isMobile: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    yearSelector: {
      display: 'flex',

      marginBottom: 5,
    },
  })
);

// Buttons used for selecting year
const YearSelect: React.FunctionComponent<YearSelectProps> = ({
  setSelectedYear,
  selectedYear,
  isMobile,
}) => {
  const handleSelect = (selected: number) => {
    if (selected !== null) {
      setSelectedYear(selected);
    }
  };

  const classes = useStyles();
  return (
    <ToggleButtonGroup
      className={classes.yearSelector}
      style={{
        justifyContent: isMobile ? 'center' : 'flex-end',
        marginRight: isMobile ? 0 : '50px',
      }}
      size="small"
      value={selectedYear}
      exclusive
      onChange={(e, selected) => handleSelect(selected)}
      aria-label="text alignment"
    >
      <ToggleButton
        key={100}
        value={100}
        aria-label="left aligned"
        color="primary"
      >
        All time
      </ToggleButton>
      <ToggleButton key={5} value={5} aria-label="left aligned" color="primary">
        5 Years
      </ToggleButton>
      <ToggleButton key={3} value={3} aria-label="centered">
        3 Years
      </ToggleButton>
      <ToggleButton key={1} value={1} aria-label="right aligned">
        1 Year
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default YearSelect;
