import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface selectableValue {
  value: string;
  label: string;
  isAbsRel: boolean;
}

interface SelectedValue {
  value: string;
  isAbsRel: boolean;
}

interface SelectorProps {
  selectableValues: selectableValue[];
  setSelectedGraph: (selected: SelectedValue) => void;
  selectedGraph: SelectedValue;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: 30,
    },
    yearSelector: {
      display: 'flex',
    },
  })
);

// Buttons used for selecting graph

const GraphSelector: React.FunctionComponent<SelectorProps> = ({
  selectableValues,
  setSelectedGraph,
  selectedGraph,
}) => {
  const classes = useStyles();

  const handleSetSelectedGraph = (selected: string) => {
    const newSelected = selectableValues.find((obj) => obj.value === selected);

    if (newSelected) {
      setSelectedGraph(newSelected);
    }
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={selectedGraph.value}
        onChange={(e, selected) => handleSetSelectedGraph(selected)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {selectableValues.map((tab) => {
          return <Tab key={tab.label} value={tab.value} label={tab.label} />;
        })}
      </Tabs>
    </div>
  );
};

export default GraphSelector;
