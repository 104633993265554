import React from 'react';
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { Link } from 'react-router-dom';
import { Highlight } from 'react-instantsearch-dom';
import { CustomHighlight } from './CustomHighlight';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(2),
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
    infoAndButtonsFlexContainer: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    buttons: {
      display: 'inline-flex',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
      },
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
  })
);

interface ResultEntryProps {
  hit: any;
}

// Component for showing results on mobile
const ResultEntry: React.FunctionComponent<ResultEntryProps> = ({ hit }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant={'h5'}>
          <CustomHighlight
            attribute="factset_fund_name"
            hit={hit}
            tagName="mark"
          />
        </Typography>{' '}
        <Typography variant={'body1'}>
          ISIN: <Highlight attribute="isin" hit={hit} tagName="mark" />
        </Typography>
        <Typography variant={'body1'}>
          Currency:{' '}
          <Highlight attribute="infront_currency" hit={hit} tagName="mark" />
        </Typography>
        <br />
      </div>

      <div className={classes.infoAndButtonsFlexContainer}>
        <div className={classes.buttons}>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<AddIcon />}
            disableElevation
          >
            Add to list
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ShowChartIcon />}
            disableElevation
            component={Link}
            to={`/${hit['isin']}/${hit['infront_currency']}`}
          >
            View
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResultEntry;
