import React from 'react';
import {
  ScreenerFieldData,
  ScreenerFieldInputType,
  FromToParameters,
  SelectParameters,
} from './interfaces';
import ScreenerInputRow from './screener-field-inputs/screener-input-row';
import FromToInput from './screener-field-inputs/from-to-input';
import SelectInput from './screener-field-inputs/select-input';
import { isNumericRangeInputType } from './screener-field-inputs/helpers';
import TextInput from './screener-field-inputs/text-input';
import ScreenerSelectInput from './algolia-ui-elements/screener-select-input';
import ScreenerFromToInput from './algolia-ui-elements/screener-from-to-input';

interface ScreenerActiveFieldInputsProps {
  activeFields: ScreenerFieldData[];
  onSetFieldValue: (index: number, newValue: any) => void;
}

const ScreenerActiveFieldInputs: React.FunctionComponent<ScreenerActiveFieldInputsProps> = ({
  activeFields,
  onSetFieldValue,
}) => {
  return (
    <>
      {activeFields.map((activeField, index) => (
        <ScreenerInputRow
          key={activeField.metadata.fieldName}
          fieldDescription={activeField.metadata.description}
          numberOfMatches={0}
        >
          {isNumericRangeInputType(activeField.metadata.fieldType) &&
            (activeField.metadata.algoliaAttribute ? (
              <ScreenerFromToInput
                fieldName={activeField.metadata.fieldName}
                attribute={activeField.metadata.algoliaAttribute}
              />
            ) : (
              <FromToInput
                fieldName={activeField.metadata.fieldName}
                isPercentageRange={
                  activeField.metadata.fieldType ===
                  ScreenerFieldInputType.PERCENTAGE_RANGE
                }
                minValueParameter={
                  (activeField.parameters as FromToParameters).minValue
                }
                maxValueParameter={
                  (activeField.parameters as FromToParameters).maxValue
                }
                onSetValidValues={(newValue) =>
                  onSetFieldValue(index, newValue)
                }
              />
            ))}

          {activeField.metadata.fieldType ===
            ScreenerFieldInputType.MULTI_SELECT &&
            (activeField.metadata.algoliaAttribute ? (
              <ScreenerSelectInput
                fieldName={activeField.metadata.fieldName}
                fieldDescription={activeField.metadata.description}
                attribute={activeField.metadata.algoliaAttribute}
                limit={100}
              />
            ) : (
              <SelectInput
                fieldName={activeField.metadata.fieldName}
                fieldDescription={activeField.metadata.description}
                optionsParameter={
                  (activeField.parameters as SelectParameters)
                    .multiSelectOptions
                }
                onSelectionChange={(selectedValues) =>
                  onSetFieldValue(index, selectedValues)
                }
              />
            ))}

          {activeField.metadata.fieldType === ScreenerFieldInputType.TEXT && (
            <TextInput
              fieldName={activeField.metadata.fieldName}
              fieldDescription={activeField.metadata.description}
              onChange={(newTextInput) => onSetFieldValue(index, newTextInput)}
            />
          )}
        </ScreenerInputRow>
      ))}
    </>
  );
};
export default ScreenerActiveFieldInputs;
