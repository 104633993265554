import { createMuiTheme } from '@material-ui/core';

const norquantTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#056778',
    },
    secondary: {
      // main: '#a8d7d2',
      main: '#f78e07',
    },
    background: {
      default: '#fff',
    },

    divider: 'rgba(0, 0, 0, 0.25)',
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
  },
});

export default norquantTheme;
