import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(2),
        },
      },
    },
    descriptionBox: {
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
      paddingTop: theme.spacing(1),
    },
    inputBox: {
      width: '100%',
    },
    numberOfMatchesBox: {
      [theme.breakpoints.up('md')]: {
        width: 100,
      },
      alignSelf: 'stretch',
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
      justifyContent: 'flex-end',
      backgroundColor: '#f5f9f8',
      padding: theme.spacing(0, 1),
      textAlign: 'right',
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  })
);

interface ScreenerInputRowProps {
  fieldDescription: string;
  numberOfMatches: number;
}

const ScreenerInputRow: React.FunctionComponent<ScreenerInputRowProps> = ({
  fieldDescription,
  numberOfMatches,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.descriptionBox}>
        <Typography variant="body2">
          <strong>{fieldDescription}</strong>
        </Typography>
      </div>

      <div className={classes.inputBox}>{children}</div>
    </div>
  );
};

export default ScreenerInputRow;
