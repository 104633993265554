import React, { useEffect, useState } from 'react';
import {
  Chip,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';

const NUMBER_OF_OPTIONS_FOR_CHIP_DESIGN_LIMIT = 10;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      // padding: theme.spacing(1),
      minHeight: 50,
    },
    optionChip: {
      // '&:not(:last-child)': {
      //   marginRight: theme.spacing(1),
      // },
      margin: theme.spacing(1),
    },
    formControl: {
      width: '100%',
    },
  })
);

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

type SelectedOptionsMapType = { [option: string]: boolean };

const getSelectedOptionsMapWithAllFalse = (optionsArray: string[]) =>
  optionsArray.reduce((acc, cur) => {
    acc[cur] = false;
    return acc;
  }, {} as SelectedOptionsMapType);

const getSelectedOptionsArray = (selectedOptionsMap: SelectedOptionsMapType) =>
  Object.entries(selectedOptionsMap)
    .filter(([option, isSelected]) => isSelected)
    .map(([option]) => option);

interface SelectInputProps {
  fieldName: string;
  fieldDescription: string;
  optionsParameter: string[];
  onSelectionChange: (selectedOptions: string[]) => void;
}

const SelectInput: React.FunctionComponent<SelectInputProps> = ({
  fieldName,
  fieldDescription,
  optionsParameter,
  onSelectionChange,
}) => {
  const classes = useStyles();

  const [selectedOptionsMap, setSelectedOptionsMap] = useState<
    SelectedOptionsMapType
  >(getSelectedOptionsMapWithAllFalse(optionsParameter));

  useEffect(() => {
    onSelectionChange(getSelectedOptionsArray(selectedOptionsMap));
  }, [selectedOptionsMap]);

  const isOptionSelected = (option: string) => selectedOptionsMap[option];

  const handleChipToggleOption = (option: string) =>
    setSelectedOptionsMap((selectedOptionsMap) => {
      const selectedOptionsMapCopy = { ...selectedOptionsMap };
      selectedOptionsMapCopy[option] = !selectedOptionsMapCopy[option];
      return selectedOptionsMapCopy;
    });

  const handleAutocompleteChange = (selectedOptions: string[]) => {
    const selectedOptionsMap = getSelectedOptionsMapWithAllFalse(
      optionsParameter
    );
    selectedOptions.forEach(
      (selectedOption) => (selectedOptionsMap[selectedOption] = true)
    );
    setSelectedOptionsMap(selectedOptionsMap);
  };

  const useChipBasedSelect = () =>
    optionsParameter.length <= NUMBER_OF_OPTIONS_FOR_CHIP_DESIGN_LIMIT;

  return useChipBasedSelect() ? (
    <div className={classes.chipBox}>
      {optionsParameter.map((option, index) => (
        <Chip
          key={index}
          className={classes.optionChip}
          label={option}
          color="primary"
          variant={isOptionSelected(option) ? 'default' : 'outlined'}
          icon={isOptionSelected(option) ? <DoneIcon /> : <AddIcon />}
          onClick={() => handleChipToggleOption(option)}
        ></Chip>
      ))}
    </div>
  ) : (
    <Autocomplete
      multiple
      id={`${fieldName}-select`}
      options={optionsParameter}
      getOptionLabel={(option) => option}
      onChange={(event, value) => handleAutocompleteChange(value)}
      // filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={fieldDescription}
          // placeholder={fieldDescription}
        />
      )}
    />
  );
};

export default SelectInput;
