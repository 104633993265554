import React, { useState } from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FactsTableProps } from './interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    yearSelector: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '50px',
    },
    container: {
      padding: theme.spacing(2),
    },
    iconButton: {
      marginLeft: '50%',
      transform: 'translate(-50%)',
      // marginTop: theme.spacing(2),
    },
    title: {
      // marginBottom: theme.spacing(2),
    },
  })
);

const displayData = (data: string) => {
  return data !== null ? data : 'Data is not available';
};

const DataTable: React.FunctionComponent<FactsTableProps> = ({ data }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  return (
    <TableContainer className={classes.container} component={Paper}>
      <Typography variant="h4" gutterBottom className={classes.title}>
        {data[0].fund_name}
      </Typography>
      {/* <Divider light /> */}
      <IconButton
        className={classes.iconButton}
        aria-label="expand row"
        size="small"
        onClick={() => setOpen(!open)}
      >
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  ISIN
                </Typography>
              </TableCell>
              <TableCell align="right">{displayData(data[0].isin)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  CUSIP
                </Typography>
              </TableCell>
              <TableCell align="right">{displayData(data[0].cusip)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Currency
                </Typography>
              </TableCell>
              <TableCell align="right">
                {displayData(data[0].currency)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Issuer
                </Typography>
              </TableCell>
              <TableCell align="right">{displayData(data[0].issuer)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Fund Transfer Agents
                </Typography>
              </TableCell>
              <TableCell align="right">
                {displayData(data[0].FundTransferAgents)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Region
                </Typography>
              </TableCell>
              <TableCell align="right">
                {displayData(data[0].investment_region)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Benchmark
                </Typography>
              </TableCell>
              <TableCell align="right">
                {displayData(data[0].benchmark)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Category
                </Typography>
              </TableCell>
              <TableCell align="right">
                {displayData(data[0].category)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Investment Objective Specialization
                </Typography>
              </TableCell>
              <TableCell align="right">
                {displayData(data[0].investment_objective_specialization)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Turnover
                </Typography>
              </TableCell>
              <TableCell align="right">
                {displayData(data[0].turnover)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Style
                </Typography>
              </TableCell>
              <TableCell align="right">{displayData(data[0].style)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Security Type
                </Typography>
              </TableCell>
              <TableCell align="right">
                {displayData(data[0].security_type)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" gutterBottom>
                  Fund Total Expense
                </Typography>
              </TableCell>
              <TableCell align="right">
                {displayData(data[0].FundTotalExpense)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Collapse>
    </TableContainer>
  );
};

export default DataTable;
