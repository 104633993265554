import React from 'react';
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spinnerLRContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    spinnerTopBottomContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  })
);

const Spinner: React.FunctionComponent<{ styleHeight?: string }> = ({
  styleHeight,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.spinnerLRContainer} style={{ height: styleHeight }}>
      <div className={classes.spinnerTopBottomContainer}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default Spinner;
