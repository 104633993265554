import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  YAxis,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { PerformanceGraphProps } from '../interfaces';
import { getYearOfSimpleDate } from '../../utils/getYearOfSimpleDate';
import calculateInterval from './calculateInterval';
import calculateYears from '../../utils/calculateYears';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { height: '100%', minHeight: '300px' },
    historyGraph: {
      marginTop: theme.spacing(4),
      height: '100%',
      minHeight: '300px',
    },
    toolTip: {
      backgroundColor: 'rgba(255,255,255, 0.6)',
      paddingTop: '1px',
      paddingBottom: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
    },
    labelText: {
      fontWeight: 'bold',
      marginBottom: '0px',
      paddingBottom: '0px',
    },
    labelHeader: {
      marginBottom: '0px',
      paddingBottom: '0px',
    },
  })
);

const CustomizedAxisTick: React.FunctionComponent<any> = ({
  x,
  y,
  stroke,
  payload,
  tickItemFormatter,
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {tickItemFormatter(payload.value)}
      </text>
    </g>
  );
};

const CustomTooltip: React.FunctionComponent<any> = ({
  active,
  payload,
  label,
}) => {
  const classes = useStyles();

  if (!payload[0]) {
    return null;
  }

  const orderContent = (payload: any) => {
    if (payload[0].payload.cumulative_benchmark_return_usd) {
      if (
        payload[0].payload.cumulative_benchmark_return_usd >
        payload[0].payload.cumulative_fund_return_usd
      ) {
        return [
          {
            color: payload[0].color,
            label: payload[0].payload.benchmark_name,
            return:
              ': ' +
              payload[0].payload.cumulative_benchmark_return_usd.toFixed(2),
          },
          {
            color: payload[1].color,
            label: payload[0].payload.fund_name,
            return:
              ': ' + payload[0].payload.cumulative_fund_return_usd.toFixed(2),
          },
        ];
      }
      return [
        {
          color: payload[1].color,
          label: payload[0].payload.fund_name,
          return:
            ': ' + payload[0].payload.cumulative_fund_return_usd.toFixed(2),
        },
        {
          color: payload[0].color,
          label: payload[0].payload.benchmark_name,
          return:
            ': ' +
            payload[0].payload.cumulative_benchmark_return_usd.toFixed(2),
        },
      ];
    }
    return [
      {
        color: payload[0].color,
        label: payload[0].payload.fund_name,
        return: ': ' + payload[0].payload.cumulative_fund_return_usd.toFixed(2),
      },
      {
        label: null,
        return: null,
      },
    ];
  };

  if (active) {
    return (
      <div className={classes.toolTip}>
        <p className={classes.labelHeader}>{label}</p>
        <div className={classes.label}>
          <p
            style={{ color: orderContent(payload)[0].color }}
            className={classes.labelText}
          >
            {orderContent(payload)[0].label} [USD]
          </p>
          <p>{orderContent(payload)[0].return}</p>
        </div>
        <div className={classes.label}>
          <p
            style={{ color: orderContent(payload)[1].color }}
            className={classes.labelText}
          >
            {orderContent(payload)[1].label} [USD]
          </p>
          <p>{orderContent(payload)[1].return}</p>
        </div>
      </div>
    );
  }
  return null;
};

const AbsolutePerformanceGraph: React.FunctionComponent<PerformanceGraphProps> = ({
  data,
  fundName,
  benchmarkName,
}) => {
  const classes = useStyles();

  const tickItemFormatter = (tickItem: any) => {
    const year = getYearOfSimpleDate(tickItem);
    if (calculateYears(data) > 1) {
      return year;
    }
    return tickItem;
  };

  return (
    <div className={classes.root}>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 50, left: 0, bottom: 60 }}
          style={{ paddingTop: 20 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#056778" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#056778" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#b3dad6" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#b3dad6" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="simple_date"
            interval={calculateInterval(data)}
            tick={<CustomizedAxisTick tickItemFormatter={tickItemFormatter} />}
          />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" />
          <Area
            name={benchmarkName}
            type="monotone"
            dataKey="cumulative_benchmark_return_usd"
            stroke="rgba(0, 0, 0, 0.54)"
            fillOpacity={0}
          />
          <Area
            name={fundName}
            type="monotone"
            dataKey="cumulative_fund_return_usd"
            stroke="#056778"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AbsolutePerformanceGraph;
