import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  Bar,
  BarChart,
  Legend,
  CartesianGrid,
  XAxis,
  Tooltip,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { FactorExposuresGraphProps } from './interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { height: '100%', minHeight: '300px' },
    historyGraph: {
      marginTop: theme.spacing(4),
      height: '100%',
      minHeight: '300px',
    },
    toolTip: {
      backgroundColor: 'rgba(255,255,255, 0.6)',
      paddingTop: '1px',
      paddingBottom: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
    },
    labelText: {
      fontWeight: 'bold',
      marginBottom: '0px',
      paddingBottom: '0px',
    },
    labelHeader: {
      marginBottom: '0px',
      paddingBottom: '0px',
    },
  })
);

const BarChartComponent: React.FunctionComponent<FactorExposuresGraphProps> = ({
  data,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 40, right: 50, left: 0, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="factor" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="absolute_exposure"
            fill="#056778"
            name="Absolute Exposure"
          />
          <Bar
            dataKey="relative_exposure"
            fill="#6bb7b6"
            name="Relative Exposure"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
