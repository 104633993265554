import React, { useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import { useParams } from 'react-router-dom';
import AbsolutePerformanceGraph from './graphs/AbsolutePerformanceGraph';
import RelativePerformanceGraph from './graphs/RelativePerformanceGraph';
import AbsoluteRiskGraph from './graphs/AbsoluteRiskGraph';
import RelativeRiskGraph from './graphs/RelativeRiskGraph';
import BarChartComponent from './barChartComponent';
import MarketFactorGraph from './graphs/MarketFactorGraph';
import FactorPerformanceAttributionGraph from './graphs/FactorPerformanceAttributionGraph';
import {
  FactsTableData,
  PerformanceDatapoint,
  RiskDatapoint,
  FactorExposuresDatapoint,
  FactorPerformanceAttributionDatapoint,
  MarketFactorPerformanceDatapoint,
  MarketFactorRiskDatapoint,
} from './interfaces';
import { getFullDateYearsAgo } from '../utils/getFullDate';
import YearSelect from './yearSelect';
import AbsoluteRelativeSelector from './absoluteRelativeSelector';
import GraphSelector from './graphSelector';
import Spinner from '../ui/spinner';
import DataTable from './dataTable';
import performQuery from '../utils/performQuery';
import displayGraph from '../utils/displayGraph';

interface FundReportRouteParams {
  ISIN: string;
  currency: string;
}

interface SelectedValue {
  value: string;
  isAbsRel: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '300px',
      paddingBottom: theme.spacing(4),
      maxWidth: '100%',
    },
    historyGraph: {
      marginTop: theme.spacing(4),
      height: '100%',
      minHeight: '300px',
      flexGrow: 1,
    },
    spinnerWrapper: {
      paddingTop: '25%',
      paddingLeft: 8,
      paddingRight: 8,
    },
    noData: {
      textAlign: 'center',
    },
    graphDescription: {
      textAlign: 'center',
    },
    graph: {
      padding: theme.spacing(2),
    },
    addButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: theme.spacing(2),
    },
  })
);

const FundReport: React.FunctionComponent = () => {
  const { ISIN } = useParams<FundReportRouteParams>();
  const { currency } = useParams<FundReportRouteParams>();

  const [factsTableData, setFactsTableData] = useState<FactsTableData[]>([]);

  const [performanceData, setPerformanceData] = useState<
    PerformanceDatapoint[]
  >([]);

  const [riskData, setRiskData] = useState<RiskDatapoint[]>([]);

  const [factorExposuresData, setFactorExposuresData] = useState<
    FactorExposuresDatapoint[]
  >([]);

  const [
    absoluteFactorPerformanceAttributionData,
    setAbsoluteFactorPerformanceAttributionData,
  ] = useState<FactorPerformanceAttributionDatapoint[]>([]);

  const [
    relativeFactorPerformanceAttributionData,
    setRelativeFactorPerformanceAttributionData,
  ] = useState<FactorPerformanceAttributionDatapoint[]>([]);

  const [
    marketFactorPerformanceData,
    setMarketFactorPerformanceData,
  ] = useState<MarketFactorPerformanceDatapoint[]>([]);

  const [marketFactorRiskData, setMarketFactorRiskData] = useState<
    MarketFactorRiskDatapoint[]
  >([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isFundLoading, setIsFundLoading] = useState(false);
  const [isMarketLoading, setIsMarketLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState<number>(1);
  const [isAbsolute, setIsAbsolute] = useState<boolean>(true);

  const [selectedFundGraph, setSelectedFundGraph] = useState<SelectedValue>({
    value: 'PREFORMANCE',
    isAbsRel: true,
  });
  const [selectedMarketGraph, setSelectedMarketGraph] = useState<SelectedValue>(
    {
      value: 'MARKET_FACTOR_PERFORMANCE',
      isAbsRel: false,
    }
  );

  const isData = (data: any) => {
    if (Array.isArray(data) && data.length > 0) {
      return true;
    }
    return false;
  };

  const displayData = (
    data: any[],
    components: JSX.Element,
    backup?: JSX.Element
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (Array.isArray(data[i]) && data[i].length > 0) {
        return components;
      }
    }
    return backup;
  };

  useEffect(() => {
    // Fetching Facts table data
    performQuery(
      `${process.env.REACT_APP_API_URL}/fund/factstable/${ISIN}/${currency}`,
      setFactsTableData
    );
    // Fetching data for the two initial graphs shown
    handleSetYear(selectedYear);
    // Timeout in case the fetching is pending for a long time
    setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 5000);
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Function for setting selected year and qurying graph data for that year
  const handleSetYear = async (year: number) => {
    setSelectedYear(year);
    await Promise.all([
      handleSelectFundGraph(selectedFundGraph, year),
      handleSelectMarketGraph(selectedMarketGraph, year),
    ]);
    setIsLoading(false);
  };

  // Query data for fund graph based on selected graph and year
  const handleSelectFundGraph = async (
    selected: SelectedValue,
    year?: number
  ) => {
    setIsFundLoading(true);

    const date = new Date();

    setSelectedFundGraph(selected);

    switch (selected.value) {
      case 'PREFORMANCE':
        await performQuery(
          `${
            process.env.REACT_APP_API_URL
          }/fund/performance/${ISIN}/${currency}/${getFullDateYearsAgo(
            date,
            year ? year : selectedYear
          )}`,
          setPerformanceData
        );
        break;
      case 'RISK':
        await performQuery(
          `${
            process.env.REACT_APP_API_URL
          }/fund/risk/${ISIN}/${currency}/${getFullDateYearsAgo(
            date,
            year ? year : selectedYear
          )}`,
          setRiskData
        );
        break;
      case 'FACTOR_EXPOSURES':
        await performQuery(
          `${process.env.REACT_APP_API_URL}/fund/factorexposures/${ISIN}/${currency}`,
          setFactorExposuresData
        );
        break;
      case 'FAC_PREF_ATTR':
        await performQuery(
          `${
            process.env.REACT_APP_API_URL
          }/fund/absfactperfattr/${ISIN}/${currency}/${getFullDateYearsAgo(
            date,
            year ? year : selectedYear
          )}`,
          setAbsoluteFactorPerformanceAttributionData
        );
        await performQuery(
          `${
            process.env.REACT_APP_API_URL
          }/fund/relfactperfattr/${ISIN}/${currency}/${getFullDateYearsAgo(
            date,
            year ? year : selectedYear
          )}`,
          setRelativeFactorPerformanceAttributionData
        );
        break;

      default:
        break;
    }
    setIsFundLoading(false);
  };

  // Query data for market graph based on selected graph and year
  const handleSelectMarketGraph = async (
    selected: SelectedValue,
    year?: number
  ) => {
    setIsMarketLoading(true);

    const date = new Date();

    setSelectedMarketGraph(selected);

    switch (selected.value) {
      case 'MARKET_FACTOR_PERFORMANCE':
        await performQuery(
          `${
            process.env.REACT_APP_API_URL
          }/fund/marketfactorperformance/${ISIN}/${currency}/${getFullDateYearsAgo(
            date,
            year ? year : selectedYear
          )}`,
          setMarketFactorPerformanceData
        );
        break;
      case 'MARKET_FACTOR_RISK':
        await performQuery(
          `${
            process.env.REACT_APP_API_URL
          }/fund/marketfactorrisk/${ISIN}/${currency}/${getFullDateYearsAgo(
            date,
            year ? year : selectedYear
          )}`,
          setMarketFactorRiskData
        );
        break;

      default:
        break;
    }
    setIsMarketLoading(false);
  };

  // Components to be displayed for the fund graphs
  const fundGraphs = [
    {
      isData: isData(performanceData),
      case: 'PREFORMANCE',
      component: (
        <AbsolutePerformanceGraph
          key="AbsolutePerformanceGraph"
          isMobile={isMobile}
          data={performanceData}
          isin={ISIN}
          years={selectedYear}
          fundName={
            factsTableData.length > 0 ? factsTableData[0].fund_name : 'Fund'
          }
          benchmarkName={
            factsTableData.length > 0
              ? factsTableData[0].benchmark
              : 'Benchmark'
          }
        />
      ),
      isAbsolute: isAbsolute,
      relativeComponent: (
        <RelativePerformanceGraph
          key="RelativePerformanceGraph"
          isMobile={isMobile}
          data={performanceData}
          isin={ISIN}
          years={selectedYear}
          fundName={
            factsTableData.length > 0 ? factsTableData[0].fund_name : 'Fund'
          }
          benchmarkName={
            factsTableData.length > 0
              ? factsTableData[0].benchmark
              : 'Benchmark'
          }
        />
      ),
      backupMessage: 'Data for the Performance graph is not available',
    },
    {
      isData: isData(riskData),
      case: 'RISK',
      component: (
        <AbsoluteRiskGraph
          key="AbsoluteRiskGraph"
          isMobile={isMobile}
          data={riskData}
          isin={ISIN}
          years={selectedYear}
          fundName={
            factsTableData.length > 0 ? factsTableData[0].fund_name : 'Fund'
          }
          benchmarkName={
            factsTableData.length > 0
              ? factsTableData[0].benchmark
              : 'Benchmark'
          }
        />
      ),
      isAbsolute: isAbsolute,
      relativeComponent: (
        <RelativeRiskGraph
          key="RelativeRiskGraph"
          isMobile={isMobile}
          data={riskData}
          isin={ISIN}
          years={selectedYear}
          fundName={
            factsTableData.length > 0 ? factsTableData[0].fund_name : 'Fund'
          }
          benchmarkName={
            factsTableData.length > 0
              ? factsTableData[0].benchmark
              : 'Benchmark'
          }
        />
      ),
      backupMessage: 'Data for the Risk graph is not available',
    },
    {
      isData: isData(factorExposuresData),
      case: 'FACTOR_EXPOSURES',
      component: (
        <BarChartComponent
          key="FACTOR_EXPOSURES"
          isMobile={isMobile}
          data={factorExposuresData}
          fundName={
            factsTableData.length > 0 ? factsTableData[0].fund_name : 'Fund'
          }
          benchmarkName={
            factsTableData.length > 0
              ? factsTableData[0].benchmark
              : 'Benchmark'
          }
        />
      ),
      backupMessage: 'Data for the Factor Exposures graph is not available',
    },
    {
      isData: isData(absoluteFactorPerformanceAttributionData),
      case: 'FAC_PREF_ATTR',
      component: (
        <FactorPerformanceAttributionGraph
          key="ABS_FAC_PREF_ATTR"
          isMobile={isMobile}
          data={absoluteFactorPerformanceAttributionData}
          years={selectedYear}
          fundName={
            factsTableData.length > 0 ? factsTableData[0].fund_name : 'Fund'
          }
          benchmarkName={
            factsTableData.length > 0
              ? factsTableData[0].benchmark
              : 'Benchmark'
          }
        />
      ),
      isAbsolute: isAbsolute,
      relativeComponent: (
        <FactorPerformanceAttributionGraph
          key="REL_FAC_PREF_ATTR"
          isMobile={isMobile}
          data={relativeFactorPerformanceAttributionData}
          years={selectedYear}
          fundName={
            factsTableData.length > 0 ? factsTableData[0].fund_name : 'Fund'
          }
          benchmarkName={
            factsTableData.length > 0
              ? factsTableData[0].benchmark
              : 'Benchmark'
          }
        />
      ),
      backupMessage:
        'Data for the Factor Performance Attribution graph is not available',
    },
  ];

  // Components to be displayed for the market graphs
  const marketGraphs = [
    {
      isData: isData(performanceData),
      case: 'MARKET_FACTOR_PERFORMANCE',
      component: (
        <MarketFactorGraph
          key="MARKET_FACTOR_PERFORMANCE"
          isMobile={isMobile}
          data={marketFactorPerformanceData}
          years={selectedYear}
          fundName={
            factsTableData.length > 0 ? factsTableData[0].fund_name : 'Fund'
          }
          benchmarkName={
            factsTableData.length > 0
              ? factsTableData[0].benchmark
              : 'Benchmark'
          }
        />
      ),
      backupMessage: 'Data for the Market Factors graph is not available',
    },
    {
      isData: isData(performanceData),
      case: 'MARKET_FACTOR_RISK',
      component: (
        <MarketFactorGraph
          key="MARKET_FACTOR_RISK"
          isMobile={isMobile}
          data={marketFactorRiskData}
          years={selectedYear}
          fundName={
            factsTableData.length > 0 ? factsTableData[0].fund_name : 'Fund'
          }
          benchmarkName={
            factsTableData.length > 0
              ? factsTableData[0].benchmark
              : 'Benchmark'
          }
        />
      ),
      backupMessage: 'Data for the Market Factors graph is not available',
    },
  ];

  return (
    <div className={classes.root}>
      {!isLoading ? (
        displayData(
          [factsTableData, performanceData],
          <div className={classes.historyGraph}>
            <div>
              <div className={classes.addButton}>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ marginRight: '10px' }}
                  startIcon={<AddIcon />}
                  disableElevation
                >
                  Add to my funds
                </Button>
              </div>
              <Grid
                container
                className={classes.root}
                spacing={2}
                style={{ margin: 0 }}
              >
                <Grid item xs={12} key="facts">
                  {factsTableData.length > 0 ? (
                    <DataTable data={factsTableData} />
                  ) : null}
                </Grid>
                <Grid item xs={12} key="fund">
                  <Paper className={classes.graph}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ textAlign: 'center' }}
                    >
                      Fund
                    </Typography>
                    <GraphSelector
                      selectableValues={[
                        {
                          value: 'PREFORMANCE',
                          label: 'Performance',
                          isAbsRel: true,
                        },

                        { value: 'RISK', label: 'Risk', isAbsRel: true },
                        {
                          value: 'FACTOR_EXPOSURES',
                          label: 'Factor Exposures',
                          isAbsRel: false,
                        },
                        {
                          value: 'FAC_PREF_ATTR',
                          label: 'Factor performance attribution',
                          isAbsRel: true,
                        },
                      ]}
                      setSelectedGraph={handleSelectFundGraph}
                      selectedGraph={selectedFundGraph}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: selectedFundGraph.isAbsRel
                          ? 'space-between'
                          : 'flex-end',
                      }}
                    >
                      {selectedFundGraph.isAbsRel ? (
                        <AbsoluteRelativeSelector
                          isAbsolute={isAbsolute}
                          setIsAbsolute={setIsAbsolute}
                          isMobile={isMobile}
                        />
                      ) : null}
                      {selectedFundGraph.value !== 'FACTOR_EXPOSURES' ? (
                        <YearSelect
                          setSelectedYear={handleSetYear}
                          selectedYear={selectedYear}
                          isMobile={isMobile}
                        />
                      ) : null}
                    </div>
                    {/* <Typography
                      className={classes.graphDescription}
                      variant="body2"
                    >
                      Indexed fund performance. Scaled to 100 at the start of
                      the period
                    </Typography> */}

                    {!isFundLoading ? (
                      displayGraph(selectedFundGraph.value, fundGraphs)
                    ) : (
                      <Spinner styleHeight="300px" />
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} key="market">
                  <Paper className={classes.graph}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ textAlign: 'center' }}
                    >
                      Market
                    </Typography>
                    <GraphSelector
                      selectableValues={[
                        {
                          value: 'MARKET_FACTOR_PERFORMANCE',
                          label: 'Factor performance of the market',
                          isAbsRel: false,
                        },
                        {
                          value: 'MARKET_FACTOR_RISK',
                          label: 'Factor risk of the market',
                          isAbsRel: false,
                        },
                      ]}
                      setSelectedGraph={handleSelectMarketGraph}
                      selectedGraph={selectedMarketGraph}
                    />
                    <YearSelect
                      setSelectedYear={handleSetYear}
                      selectedYear={selectedYear}
                      isMobile={isMobile}
                    />
                    {!isMarketLoading ? (
                      displayGraph(selectedMarketGraph.value, marketGraphs)
                    ) : (
                      <Spinner styleHeight="300px" />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>,
          <Typography className={classes.noData} variant="h4" gutterBottom>
            Data for fund {ISIN} is currently not available
          </Typography>
        )
      ) : (
        <div className={classes.spinnerWrapper}>
          <Spinner styleHeight="50vh" />
        </div>
      )}
    </div>
  );
};

export default FundReport;
