import React, { useEffect } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchBoxProvided } from 'react-instantsearch-core';
import {
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  })
);

const SearchBox: React.FunctionComponent<SearchBoxProvided> = ({
  currentRefinement, // current search query
  refine, // function to change query
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (currentRefinement === '') refine(' ');
  }, []);

  return (
    <TextField
      id="input-fund-name"
      label="Fund name, ISIN or issuer"
      variant="outlined"
      classes={{
        root: classes.root,
      }}
      value={currentRefinement}
      onChange={(event) => refine(event.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default connectSearchBox(SearchBox);
