import React, { useState, useEffect } from 'react';
import {
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { connectRange } from 'react-instantsearch-dom';

import { FromToValues } from '../interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(2),
        },
      },
    },
    fromToInput: {
      flexGrow: 1,
    },
    hyphen: {
      paddingTop: theme.spacing(2),
    },
    minMaxValueBox: {
      paddingTop: theme.spacing(1),
    },
  })
);

interface InstantSearchRangeProvidedProps {
  currentRefinement: { min: number; max: number };
  min: number;
  max: number;
  refine: (refinement: { min: number; max: number }) => void;
}

interface FromToInputProps {
  fieldName: string;
  isPercentageRange: boolean;
  onSetValidValues: (fromToValues: FromToValues) => void;
}

const FromToInput: React.FunctionComponent<
  InstantSearchRangeProvidedProps & FromToInputProps
> = ({ fieldName, isPercentageRange, min, max, currentRefinement, refine }) => {
  const classes = useStyles();

  const [fromInputValue, setFromInputValue] = useState<string>('');
  const [toInputValue, setToInputValue] = useState<string>('');
  const [fromValueValidationError, setFromValueValidationError] = useState<
    string
  >('');
  const [toValueValidationError, setToValueValidationError] = useState<string>(
    ''
  );
  const [
    showPotentialFromValueValidationError,
    setShowPotentialFromValueValidationError,
  ] = useState<boolean>(false);
  const [
    showPotentialToValueValidationError,
    setShowPotentialToValueValidationError,
  ] = useState<boolean>(false);

  /** Returns validation error string if from value is not valid */
  const validateFromValue = (fromInputValue: string, toInputValue: string) => {
    const currentFromValue = Number(fromInputValue);

    if (isNaN(currentFromValue)) {
      return 'Must be a number';
    }
    if (currentFromValue < min) {
      return `Cannot be lower than ${min}`;
    } else if (currentFromValue > max) {
      return `Cannot be higher than ${max}`;
    } else if (toInputValue && currentFromValue > Number(toInputValue)) {
      return `Cannot be higher than the "to value"`;
    }
    return '';
  };

  /** Returns validation error string if to value is not valid */
  const validateToValue = (fromInputValue: string, toInputValue: string) => {
    const currentToValue = Number(toInputValue);

    if (isNaN(currentToValue)) {
      return 'Must be a number';
    }
    if (currentToValue > max) {
      return `Cannot be higher than ${max}`;
    } else if (currentToValue < min) {
      return `Cannot be lower than ${min}`;
    } else if (fromInputValue && currentToValue < Number(fromInputValue)) {
      return `Cannot be lower than the "from value"`;
    }
    return '';
  };

  useEffect(() => {
    const submitValuesIfBothValuesAreSetAndValid = () => {
      const bothValuesHaveBeenSet = () =>
        Boolean(fromInputValue && toInputValue);
      const thereAreNoValidationErrors = () =>
        !fromValueValidationError && !toValueValidationError;

      if (bothValuesHaveBeenSet() && thereAreNoValidationErrors()) {
        refine({
          min: Number(fromInputValue),
          max: Number(toInputValue),
        });
      }
    };
    submitValuesIfBothValuesAreSetAndValid();
  }, [
    fromInputValue,
    toInputValue,
    fromValueValidationError,
    toValueValidationError,
  ]);

  const handleFromInputChange = (fromInputValue: string) => {
    setFromInputValue(fromInputValue);

    const fromValueValidationError = validateFromValue(
      fromInputValue,
      toInputValue
    );
    setFromValueValidationError(fromValueValidationError);

    if (!fromValueValidationError) {
      // This is so that new validation errors occuring later while editing won't show up while editing
      setShowPotentialFromValueValidationError(false);

      // Check if this resolves error for other field and if so, remove validation error the for other field
      const toValueValidationError = validateToValue(
        fromInputValue,
        toInputValue
      );
      if (!toValueValidationError) {
        setToValueValidationError('');
      }
    }
  };

  const handleFromInputBlur = () => {
    const validationError = validateFromValue(fromInputValue, toInputValue);
    setFromValueValidationError(validationError);

    if (validationError) {
      setShowPotentialFromValueValidationError(true);
    }
  };

  const handleToInputChange = (toInputValue: string) => {
    setToInputValue(toInputValue);

    const toValueValidationError = validateToValue(
      fromInputValue,
      toInputValue
    );
    setToValueValidationError(toValueValidationError);

    if (!toValueValidationError) {
      setShowPotentialToValueValidationError(false);

      const fromValueValidationError = validateFromValue(
        fromInputValue,
        toInputValue
      );
      if (!fromValueValidationError) {
        setFromValueValidationError('');
      }
    }
  };

  const handleToInputBlur = () => {
    const validationError = validateToValue(fromInputValue, toInputValue);
    setToValueValidationError(validationError);

    if (validationError) {
      setShowPotentialToValueValidationError(true);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.minMaxValueBox}>
        <Typography variant="body2">
          Min:
          <br />
          {min}
          {isPercentageRange && '%'}
        </Typography>
      </div>

      <TextField
        id={`input-${fieldName}-from`}
        type="number"
        className={classes.fromToInput}
        label="From"
        variant="outlined"
        value={fromInputValue}
        onChange={(event) => handleFromInputChange(event.target.value)}
        onBlur={handleFromInputBlur}
        error={
          showPotentialFromValueValidationError &&
          fromValueValidationError !== ''
        }
        helperText={
          showPotentialFromValueValidationError && fromValueValidationError
        }
      ></TextField>

      <div className={classes.hyphen}>
        <Typography variant="body1">-</Typography>
      </div>

      <TextField
        id={`input-${fieldName}-to`}
        type="number"
        className={classes.fromToInput}
        label="To"
        variant="outlined"
        value={toInputValue}
        onChange={(event) => handleToInputChange(event.target.value)}
        onBlur={handleToInputBlur}
        error={
          showPotentialToValueValidationError && toValueValidationError !== ''
        }
        helperText={
          showPotentialToValueValidationError && toValueValidationError
        }
      ></TextField>

      <div className={classes.minMaxValueBox}>
        <Typography variant="body2">
          Max:
          <br />
          {max}
          {isPercentageRange && '%'}
        </Typography>
      </div>
    </div>
  );
};

export default connectRange(FromToInput);
