import { connectHighlight } from 'react-instantsearch-dom';

const CHighlight: React.FunctionComponent<any> = ({
  highlight,
  attribute,
  hit,
}) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <span>
      {parsedHit.map((part: any, index: any) =>
        part.isHighlighted ? (
          <mark style={{ fontSize: '16px' }} key={index}>
            {part.value.split(' Units')[0]}
          </mark>
        ) : (
          <span style={{ fontSize: '16px' }} key={index}>
            {part.value.split(' Units')[0]}
          </span>
        )
      )}
    </span>
  );
};

export const CustomHighlight = connectHighlight(CHighlight);
