import React from 'react';
import {
  Chip,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import { connectRefinementList } from 'react-instantsearch-dom';
import { RefinementListProvided, Hit } from 'react-instantsearch-core';

const NUMBER_OF_OPTIONS_FOR_CHIP_DESIGN_LIMIT = 8;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      // padding: theme.spacing(1),
      minHeight: 50,
    },
    optionChip: {
      // '&:not(:last-child)': {
      //   marginRight: theme.spacing(1),
      // },
      margin: theme.spacing(1),
    },
    formControl: {
      width: '100%',
    },
  })
);

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

type RefinementListItem = Hit<{
  count: number;
  isRefined: boolean;
  label: string;
  value: string[];
}>;

interface SelectInputProps {
  fieldName: string;
  fieldDescription: string;
}

const ScreenerSelectInput: React.FunctionComponent<
  RefinementListProvided & SelectInputProps
> = ({ fieldName, fieldDescription, items, currentRefinement, refine }) => {
  const classes = useStyles();

  const sortedItems = items.sort((itemA, itemB) =>
    itemA.label.localeCompare(itemB.label)
  );

  const handleAutocompleteChange = (selectedItems: RefinementListItem[]) => {
    const selectedItemLabels = selectedItems.map(
      (selectedItem) => selectedItem.label
    );

    for (const item of items) {
      if (item.isRefined !== selectedItemLabels.includes(item.label)) {
        refine(item.value);
        break;
      }
    }
  };

  const useChipBasedSelect = () =>
    items.length <= NUMBER_OF_OPTIONS_FOR_CHIP_DESIGN_LIMIT;

  return useChipBasedSelect() ? (
    <div className={classes.chipBox}>
      {sortedItems.map((item) => (
        <Chip
          key={item.label}
          className={classes.optionChip}
          label={`${item.label} (${item.count})`}
          color="primary"
          variant={item.isRefined ? 'default' : 'outlined'}
          icon={item.isRefined ? <DoneIcon /> : <AddIcon />}
          onClick={() => refine(item.value)}
        ></Chip>
      ))}
    </div>
  ) : (
    <Autocomplete
      multiple
      id={`${fieldName}-select`}
      options={sortedItems}
      getOptionLabel={(item) => `${item.label} (${item.count})`}
      onChange={(event, value) => handleAutocompleteChange(value)}
      value={sortedItems.filter((item) => item.isRefined)}
      // filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={fieldDescription}
          // placeholder={fieldDescription}
        />
      )}
    />
  );
};

export default connectRefinementList(ScreenerSelectInput);
