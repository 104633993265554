import calculateYears from '../../utils/calculateYears';

const calculateInterval = (data: any[]) => {
  const years = calculateYears(data);
  if (years > 1 && years <= 5) {
    return Math.ceil(data.length / years);
  } else if (years > 5) {
    return Math.ceil(data.length / ((1 / 10) * years));
  }
  return Math.floor(data.length / 3.5);
};

export default calculateInterval;
