import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  YAxis,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { RiskGraphProps } from '../interfaces';
import { getYearOfSimpleDate } from '../../utils/getYearOfSimpleDate';
import calculateInterval from './calculateInterval';
import calculateYears from '../../utils/calculateYears';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { height: '100%', minHeight: '300px' },
    historyGraph: {
      marginTop: theme.spacing(4),
      height: '100%',
      minHeight: '300px',
    },
    toolTip: {
      backgroundColor: 'rgba(255,255,255, 0.6)',
      paddingTop: '1px',
      paddingBottom: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
    },
    labelText: {
      fontWeight: 'bold',
      marginBottom: '0px',
      paddingBottom: '0px',
    },
    labelHeader: {
      marginBottom: '0px',
      paddingBottom: '0px',
    },
  })
);

const CustomizedXAxisTick: React.FunctionComponent<any> = ({
  x,
  y,
  stroke,
  payload,
  tickItemFormatter,
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {tickItemFormatter(payload.value)}
      </text>
    </g>
  );
};

const CustomizedYAxisLabel: React.FunctionComponent<any> = ({
  x,
  y,
  stroke,
  payload,
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        dy={16}
        dx={-80}
        textAnchor="end"
        fill="#666"
        transform="rotate(-90)"
      >
        Tracking Error
      </text>
    </g>
  );
};

const CustomTooltip: React.FunctionComponent<any> = ({
  active,
  payload,
  label,
}) => {
  const classes = useStyles();

  if (active && payload[0]) {
    return (
      <div className={classes.toolTip}>
        <p className={classes.labelHeader}>{label}</p>
        <div className={classes.label}>
          <p style={{ color: payload[0].color }} className={classes.labelText}>
            Tracking Error
          </p>
          <p>: {payload[0].payload.tracking_error.toFixed(2)}</p>
        </div>
      </div>
    );
  }
  return null;
};

const AbsoluteRiskGraph: React.FunctionComponent<RiskGraphProps> = ({
  data,
  fundName,
}) => {
  const classes = useStyles();

  const tickItemFormatter = (tickItem: any) => {
    const year = getYearOfSimpleDate(tickItem);
    if (calculateYears(data) > 1) {
      return year;
    }
    return tickItem;
  };

  return (
    <div className={classes.root}>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 50, left: 0, bottom: 60 }}
          style={{ paddingTop: 20 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#056778" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#056778" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#b3dad6" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#b3dad6" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="simple_date"
            interval={calculateInterval(data)}
            tick={<CustomizedXAxisTick tickItemFormatter={tickItemFormatter} />}
          />
          <YAxis label={<CustomizedYAxisLabel />} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" />
          <Area
            name={fundName}
            type="monotone"
            dataKey="tracking_error"
            stroke="#056778"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AbsoluteRiskGraph;
