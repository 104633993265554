import React, { useState, useContext, createContext } from 'react';

const ScreenerContext = createContext({});

//Hook for getting and updating data from the Screener Context Provider
export const useScreener = () => {
  return useContext(ScreenerContext);
};

interface ScreenerProviderProps {
  children: React.ReactNode;
}

export interface ScreenerContextProps {
  handleSetIndexName: (attributeName: string) => void;
  indexName: string;
  orderBy: string;
  isDesc: boolean;
}

// Component for storing and setting the index to use in Algolia
// based on which parameter to sort on and if it is Descending or Ascending
export const ScreenerProvider: React.FunctionComponent<ScreenerProviderProps> = ({
  children,
}) => {
  const [indexName, setIndexName] = useState<string>('fund_data');
  const [orderBy, setOrderBy] = useState<string>('');
  const [isDesc, setIsDesc] = useState<boolean>(true);

  const handleSetIndexName = (attributeName: string) => {
    setOrderBy(orderBy);
    setIsDesc(!isDesc);
    const order = isDesc ? 'descending' : 'ascending';
    const newIndex = 'fund_data_' + attributeName + '_' + order;
    setIndexName(newIndex);
  };

  const state = {
    handleSetIndexName,
    indexName,
    orderBy,
    isDesc,
  };

  return (
    <ScreenerContext.Provider value={state}>
      {children}
    </ScreenerContext.Provider>
  );
};
