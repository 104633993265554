import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  CartesianGrid,
  XAxis,
  Tooltip,
  YAxis,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts';
import { FactorPerformanceAttributionGraphProps } from '../interfaces';
import { getYearOfSimpleDate } from '../../utils/getYearOfSimpleDate';
import CheckboxLabels from './CheckBoxGroup';
import calculateInterval from './calculateInterval';
import CustomTooltip from './CustomTooltip';
import calculateYears from '../../utils/calculateYears';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { height: '100%', minHeight: '300px' },
    historyGraph: {
      marginTop: theme.spacing(4),
      height: '100%',
      minHeight: '300px',
    },
    toolTip: {
      backgroundColor: 'rgba(255,255,255, 0.6)',
      paddingTop: '1px',
      paddingBottom: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
    },
    labelText: {
      fontWeight: 'bold',
      marginBottom: '0px',
      paddingBottom: '0px',
    },
    labelHeader: {
      marginBottom: '0px',
      paddingBottom: '0px',
    },
  })
);

const CustomizedAxisTick: React.FunctionComponent<any> = ({
  x,
  y,
  stroke,
  payload,
  tickItemFormatter,
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {tickItemFormatter(payload.value)}
      </text>
    </g>
  );
};

const FactorPerformanceAttributionGraph: React.FunctionComponent<FactorPerformanceAttributionGraphProps> = ({
  data,
  isMobile,
}) => {
  const classes = useStyles();

  const [checked, setChecked] = useState({
    simple_date: true,
    value_date: true,
    absolute_fund_return: true,
    market_return_contribution: true,
    small_cap_return_contribution: true,
    value_return_contribution: true,
    quality_return_contribution: true,
    momentum_return_contribution: true,
    low_beta_return_contribution: true,
    aggregate_factor_return_contribution: true,
    alpha_return: true,
  });

  const tickItemFormatter = (tickItem: any) => {
    const year = getYearOfSimpleDate(tickItem);
    if (calculateYears(data) > 1) {
      return year;
    }
    return tickItem;
  };

  return (
    <div className={classes.root}>
      <CheckboxLabels checked={checked} setChecked={setChecked} />
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{ top: 10, right: 50, left: 0, bottom: 60 }}
          style={{ paddingTop: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="simple_date"
            interval={calculateInterval(data)}
            tick={<CustomizedAxisTick tickItemFormatter={tickItemFormatter} />}
          />
          <YAxis />
          {isMobile ? null : <Tooltip content={<CustomTooltip />} />}
          {/* <Legend verticalAlign="top" /> */}
          {checked.absolute_fund_return ? (
            <Line
              type="monotone"
              dataKey="absolute_fund_return"
              name="Absolute Return"
              stroke="#6bb7b6"
              dot={false}
            />
          ) : null}
          {checked.market_return_contribution ? (
            <Line
              type="monotone"
              dataKey="market_return_contribution"
              name="Market Return"
              stroke="#ffc505"
              dot={false}
            />
          ) : null}
          {checked.small_cap_return_contribution ? (
            <Line
              type="monotone"
              dataKey="small_cap_return_contribution"
              name="Small Cap Return"
              stroke="rgba(0, 0, 0, 0.54)"
              dot={false}
            />
          ) : null}
          {checked.value_return_contribution ? (
            <Line
              type="monotone"
              dataKey="value_return_contribution"
              name="Value Return"
              stroke="#93ed39"
              dot={false}
            />
          ) : null}
          {checked.quality_return_contribution ? (
            <Line
              type="monotone"
              dataKey="quality_return_contribution"
              name="Quality Return"
              stroke="#b84dff"
              dot={false}
            />
          ) : null}
          {checked.momentum_return_contribution ? (
            <Line
              type="monotone"
              dataKey="momentum_return_contribution"
              name="Momentum Return"
              stroke="#b84a4f"
              dot={false}
            />
          ) : null}
          {checked.low_beta_return_contribution ? (
            <Line
              type="monotone"
              dataKey="low_beta_return_contribution"
              name="Low Beta Return"
              stroke="#b84daa"
              dot={false}
            />
          ) : null}

          {checked.aggregate_factor_return_contribution ? (
            <Line
              type="monotone"
              dataKey={'aggregate_factor_return_contribution'}
              name="Aggregate Factor Return"
              stroke="#17889b"
              dot={false}
            />
          ) : null}
          {checked.alpha_return ? (
            <Line
              type="monotone"
              dataKey="alpha_return"
              name="Alpha Return"
              stroke="#141c24"
              dot={false}
            />
          ) : null}
          {checked.alpha_return ? (
            <Line
              type="monotone"
              name="dummy"
              strokeWidth={0}
              stroke="#141c24"
              dot={false}
            />
          ) : null}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FactorPerformanceAttributionGraph;
