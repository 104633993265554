import axios from 'axios';

export default async function performQuery(
  path: string,
  setData: (data: any) => void
) {
  const repsonse = await axios(path, {
    method: 'GET',
  });
  if (repsonse.data && repsonse.data.length > 0) {
    setData(repsonse.data);
  }
}
