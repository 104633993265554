import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface SelectorProps {
  setIsAbsolute: (selected: boolean) => void;
  isAbsolute: boolean;
  isMobile: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    yearSelector: {
      display: 'flex',
      marginBottom: 5,
    },
  })
);

const AbsoluteRelativeSelector: React.FunctionComponent<SelectorProps> = ({
  setIsAbsolute,
  isAbsolute,
  isMobile,
}) => {
  const classes = useStyles();
  return (
    <ToggleButtonGroup
      className={classes.yearSelector}
      style={{
        justifyContent: isMobile ? 'center' : 'flex-end',
        marginLeft: isMobile ? 0 : '50px',
      }}
      size="small"
      value={isAbsolute}
      exclusive
      onChange={(e, selected) => setIsAbsolute(selected)}
      aria-label="text alignment"
    >
      <ToggleButton
        key="true"
        value={true}
        aria-label="left aligned"
        color="primary"
      >
        Absolute
      </ToggleButton>
      <ToggleButton key="false" value={false} aria-label="centered">
        Relative
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default AbsoluteRelativeSelector;
