import React from 'react';
import { Typography } from '@material-ui/core';

interface dispayDatapoint {
  isData: boolean;
  case: string;
  component: JSX.Element;
  isAbsolute?: boolean;
  relativeComponent?: JSX.Element;
  backupMessage: string;
}

const absoluteRelativeDisplay = (
  isAbsolute: boolean,
  absoluteComponent: JSX.Element,
  relativeComponent: JSX.Element
) => {
  return isAbsolute ? absoluteComponent : relativeComponent;
};

const displaySelectedFundGraph = (
  selected: string,
  displayData: dispayDatapoint[]
) => {
  return displayData.map((datapoint) => {
    switch (selected) {
      case datapoint.case:
        return datapoint.isData ? (
          datapoint.isAbsolute !== undefined && datapoint.relativeComponent ? (
            absoluteRelativeDisplay(
              datapoint.isAbsolute,
              datapoint.component,
              datapoint.relativeComponent
            )
          ) : (
            datapoint.component
          )
        ) : (
          <Typography
            style={{ textAlign: 'center', padding: 50 }}
            variant="body1"
          >
            {datapoint.backupMessage}
          </Typography>
        );
    }
    return null;
  });
};

export default displaySelectedFundGraph;
