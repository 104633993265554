import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    textField: {
      width: '100%',
    },
  })
);

interface TextInputProps {
  fieldName: string;
  fieldDescription: string;
  onChange: (newValue: string) => void;
}

const TextInput: React.FunctionComponent<TextInputProps> = ({
  fieldName,
  fieldDescription,
  onChange,
}) => {
  const classes = useStyles();

  const [inputText, setInputText] = useState('');

  useEffect(() => {
    onChange(inputText);
  }, [inputText]);

  return (
    <div className={classes.root}>
      <TextField
        id={`input-${fieldName}`}
        type="text"
        className={classes.textField}
        label={fieldDescription}
        variant="outlined"
        value={inputText}
        onChange={(event) => setInputText(event.target.value)}
      ></TextField>
    </div>
  );
};

export default TextInput;
