import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolTip: {
      backgroundColor: 'rgba(255,255,255, 0.6)',
      paddingTop: '1px',
      paddingBottom: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
    },
    labelText: {
      fontWeight: 'bold',
      marginBottom: '0px',
      paddingBottom: '0px',
    },
    labelHeader: {
      marginBottom: '0px',
      paddingBottom: '0px',
    },
    payload: {
      padding: theme.spacing(2),
    },
  })
);

const CustomTooltip: React.FunctionComponent<any> = ({
  active,
  payload,
  label,
}) => {
  const classes = useStyles();

  let payload1 = payload;
  let payload2 = [];

  if (payload && payload.length > 3) {
    payload1 = payload.slice(0, payload.length / 2);
    payload2 = payload.slice(payload.length / 2 + 1, payload.length);
  }

  if (active) {
    return (
      <div className={classes.toolTip}>
        <div className={classes.payload}>
          {payload[0].payload.simple_date}
          {payload1.map((dataPoint: any, key: number) => (
            <div key={'p1' + key} className={classes.label}>
              <p
                className={classes.labelText}
                style={{ color: dataPoint.stroke }}
              >
                {dataPoint.name}:
              </p>
              <p>{dataPoint.value.toFixed(2)}</p>
            </div>
          ))}
        </div>
        <div className={classes.payload}>
          {payload2.map((dataPoint: any, key: number) => (
            <div key={'p2' + key} className={classes.label}>
              <p
                className={classes.labelText}
                style={{ color: dataPoint.stroke }}
              >
                {dataPoint.name}:
              </p>
              <p>{dataPoint.value.toFixed(2)}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
