import React from 'react';
import { connectStats } from 'react-instantsearch-dom';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    numberOfResultsText: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
  })
);

const ScreenerResults: React.FunctionComponent<any> = ({ nbHits }) => {
  const classes = useStyles();

  return (
    <Typography variant="body1" className={classes.numberOfResultsText}>
      <strong>{nbHits} results</strong>
    </Typography>
  );
};

export default connectStats(ScreenerResults);
