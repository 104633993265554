interface data {
  value_date: string;
}

export default function calculateYears(data: data[]) {
  const startDate = new Date(data[0].value_date);
  const endDate = new Date(data[data.length - 1].value_date);
  const numberOfDays =
    (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
  const years = Math.floor(numberOfDays / 365);
  return years;
}
